import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { afterSentMessage } from "../data/contact.js"
import contactStyles from "./css/contact.module.styl"
const { contact, thanks, isSent, avatar, kagaya } = contactStyles

const IndexPage = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale
  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={
          language !== "ja"
            ? "Thanks for your message!"
            : "お問い合わせありがとうございます！"
        }
        keywords={[`お問い合わせ`, `Contact`, `放射線像`, `Autoradiograph`]}
      />
      <section className={`${contact} ${thanks} component`}>
        <figure className={`${avatar} ${kagaya}`}></figure>

        <p className={isSent}>{afterSentMessage.success[language]}</p>
      </section>
    </Layout>
  )
}

export default IndexPage
